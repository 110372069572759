.container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.noPageHeader {
    height: 100vh;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-gray-basic);
}

.backgroundContainer {
  position: fixed;
  display: none;
  width: 80rem;
  z-index: -1;
  left: 50%;
  top: 35rem;
  transform: translate(-50%, -50%);

  @media (--viewport-m) {
    display: block;
  }
}
