.container {
  align-items: center;
  box-shadow: 0.4rem 0.6rem 0.7rem rgb(50 50 50 / 10%);
  background-color: var(--color-gray-basic);
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 2.4rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-page-header);

  @media (--viewport-l) {
    padding: 2.4rem 4rem;
  }

  &.transparent {
    @media (--viewport-l) {
      box-shadow: none;
      background-color: var(--color-transparent);
      border-bottom: 0;
    }
  }

  &.positionAbsolute {
    position: absolute;
  }
}

.logo svg {
  height: 6.2rem;
  width: 6.2rem;

  & > * {
    cursor: pointer;
  }
}

.navigation {
  background-color: var(--color-gray-basic);
  bottom: 0;
  height: calc(100% - 7.3rem); /* Menubutton height + 2 * vertical padding of container */
  left: 0;
  padding: 2.4rem 1.6rem;
  position: fixed;
  transform: translateX(-100%);
  transition: transform 150ms ease-out;
  width: 100%;
  z-index: var(--z-index-page-header);

  & a {
    text-decoration: none;
  }

  @media (--viewport-l) {
    background: transparent;
    display: block;
    padding: 0;
    transform: translateX(0%);
    width: auto;
    position: sticky;
    top: 0;

    &.isOpen {
      display: block;
    }
  }

  &.isOpen {
    display: flex;
    transform: translateX(0%);
  }

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @media (--viewport-l) {
      display: flex;
    }

    & li:not(:last-child) {
      margin-bottom: 4rem;

      @media (--viewport-l) {
        margin-bottom: 0;
        margin-right: 4rem;
      }
    }
  }
}

.navigationLink {
  font-size: var(--font-size-l);
  font-family: var(--font-family);
  line-height: var(--line-height-m);
  color: var(--color-body);
  padding: 0.8rem 1.2rem;
  position: relative;
  border-radius: var(--border-radius-button);

  &:hover,
  &:focus {
    transition: all 0.2s;
    background-color: var(--color-gray-bright);
  }

  & > :not(:last-child) {
    margin-right: 1.6rem;
  }

  & span {
    cursor: pointer;
  }

  @media (--viewport-l) {
    padding: 0.8rem 1.2rem;
    font-size: var(--font-size-l);
  }
}

.active {
  font-family: var(--font-family-bold);
  font-weight: var(--font-weight-bold);
}

.menuButton {
  --menu-button-color: #0f172a;

  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1.6rem 1.6rem;

  & > * {
    margin-bottom: -0.6rem;
  }

  & svg {
    height: 2rem;
    color: var(--menu-button-color);
  }

  @media (--viewport-l) {
    display: none;
  }
}

.badge {
  align-items: center;
  background-color: var(--color-blue-information);
  border-radius: var(--border-radius-round);
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-bold);
  height: 2.4rem;
  justify-content: center;
  line-height: var(--line-height-s);
  margin-top: 0.4rem;
  width: 2.4rem;
  position: absolute;
  right: 0;
  top: 0;
}
