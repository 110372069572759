/* stylelint-disable at-rule-no-unknown */
@tailwind components;
@tailwind utilities;

:root {
  --page-header-height-mobile: 9.4rem;
  --page-header-height-large: 12rem;
  --color-schaduw: rgba(0 0 0 / 5%);
  --font-family: 'Montserrat-Light', serif;
  --font-family-medium: 'Montserrat-Medium', serif;
  --font-family-bold: 'Montserrat-Bold', serif;
  --font-family-semibold: 'Montserrat-SemiBold', serif;
  --font-family-headings: 'NordiquePro-Bold', serif;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --line-height-s: 1;
  --line-height-m: 1.2;
  --line-height-l: 1.5;
  --line-height-xl: 3.2rem;
  --font-size-4xl: 6.4rem;
  --font-size-xxxl: 4.8rem;
  --font-size-xxl: 4rem;
  --font-size-xl: 2.8rem;
  --font-size-l: 2rem;
  --font-size-m: 1.8rem;
  --font-size-s: 1.6rem;
  --font-size-xs: 1.4rem;
  --font-size-xxs: 1.2rem;
  --font-size-xxxs: 1rem;
  --font-size-4xs: 0.8rem;
  --container-s: 53.4rem;
  --container-m: 80rem;
  --container-l: 124.8rem;
  --z-index-page-header: 1000;
  --z-index-chat-header: 1001;
  --z-index-popup: 10000;
  --z-index-overlay: 1000000;
  --box-shadow: 0 0.4rem 0.4rem rgb(0 0 0 / 5%);
  --border-radius: 0.4rem;
  --border-radius-large: 0.8rem;
  --border-radius-button: 2rem;
  --border-radius-extralarge: 3.2rem;
  --border-radius-full: 6.2rem;
  --border-radius-round: 50%;

  /* colors */

  --color-white: #fff;
  --color-black: #000;
  --color-gray-4: #e1e6e6;
  --color-gray-2: #7d8080;
  --color-gray-dark: #3e4040;
  --color-gray-basic: #faffff;
  --color-gray: #b8afaf;
  --color-gray-light: #e5e5e5;
  --color-gray-bright: #f4f4f4;
  --color-darkblue: #003;
  --color-light-green: #89bd22;
  --color-lime-green: #b8fc2d;
  --color-bright-green: #ebffc2;
  --color-lime-green-light: #e6ffb8;
  --color-body: var(--color-darkblue);
  --color-light-blue: #4de4ff;
  --color-button-primary-hover: #d0ff54;
  --color-red: #d92626;
  --color-blue-information: #0172cb;
  --color-blue-info-between: #4e9ed4;
  --color-blue-information-light: #e1f1ff;
  --color-blue-information-lightest: #ebf4fb;

  /* todo: clean up these when we remove the match percentage component */
  --color-match-good: #398e4b;
  --color-match-bg-good: #d7efdc;
  --color-match-ok: #5fa441;
  --color-match-bg-ok: #deefd7;
  --color-match-medium: #dc602e;
  --color-match-bg-medium: #f9e4dc;
  --color-match-bad: #dd3737;
  --color-match-bg-bad: #f5e0e0;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  width: 1.6rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-2);
  border-radius: var(--border-radius-extralarge);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 62.5%; /* 1rem = 10px */
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-gray-basic);
  color: var(--color-body);
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  margin: 0;
  padding-top: var(--page-header-height-mobile);
  width: 100%;

  @media (--viewport-l) {
    padding-top: var(--page-header-height-large);
  }

  &.noPageHeader {
    padding-top: 0;
    height: 100vh;

    @media (--viewport-m) {
      padding-top: 0;
    }
  }
}

input,
textarea,
select {
  font-family: var(--font-family);
}

button {
  font-family: var(--font-family-headings);
}

@font-face {
  font-display: swap;
  font-family: NordiquePro-Bold;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  src: url('../assets/fonts/NordiquePro-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Montserrat-Medium;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  src: url('../assets/fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Montserrat-Light;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  src: url('../assets/fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  src: url('../assets/fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Montserrat-SemiBold;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  src: url('../assets/fonts/Montserrat-SemiBold.woff') format('woff');
}
